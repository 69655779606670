import { VCardText } from 'vuetify/lib/components/VCard';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "title": _setup.$t('selectTeam.teamInfo'),
      "actions": {
        false: _setup.$t('action.cancel'),
        true: _setup.props.teamId ? _setup.$t('action.save') : _setup.$t('action.create')
      },
      "valid": _setup.isValid,
      "loading": _setup.loading,
      "before-close": _setup.beforeClose,
      "persistent": "",
      "width": "600px"
    },
    on: {
      "click": _setup.onClick,
      "close": function close($event) {
        return _setup.emits('close');
      }
    }
  }, [_c(VCardText, {
    staticClass: "py-2"
  }, [_c('Input', {
    staticClass: "mt-4",
    attrs: {
      "initial-value": _setup.initialValue.name,
      "label": _setup.$t('selectTeam.teamName'),
      "required": "",
      "clearable": ""
    },
    model: {
      value: _setup.name,
      callback: function callback($$v) {
        _setup.name = $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "initial-value": _setup.initialValue.description,
      "label": _setup.$t('selectTeam.teamDetail'),
      "clearable": ""
    },
    model: {
      value: _setup.description,
      callback: function callback($$v) {
        _setup.description = $$v;
      },
      expression: "description"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };